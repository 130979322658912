import React from 'react';
import injectSheet from 'react-jss';
import sharedStyles from '../sharedStyles';

// components
import EmojiButton from '../EmojiButton';
import ModalWrapper from '../../ModalWrapper';
import ReactInView from 'react-inview-monitor';

class OurGoal extends React.PureComponent {
  render() {
    const { classes } = this.props;

    return (
      <section className={classes.root}>
        <div className={classes.content}>
          <div className={classes.textBlock}>
            <h3 className={classes.title}>
              We're targeting every center, {` `}
              <div className={classes.mobileLineBreak} /> {` `}
              across the U.S.A.
            </h3>
            <div className={classes.subtitle}>
              We add any test center requested to us. If you cannot find your testing center, send
              us a message. We will get back to you.
              <ModalWrapper type="SuggestLocation" className={classes.modalWrapper}>
                <EmojiButton emoji="🌎" label="Suggest a test center" />
              </ModalWrapper>
            </div>
          </div>
        </div>
        <div className={classes.content}>
          <ReactInView classNameInView={classes.inView} classNameNotInView={classes.notInView}>
            <div className={classes.circle} />
          </ReactInView>
        </div>
      </section>
    );
  }
}

const styles = theme => {
  const { root, notInView, inView, ...other } = sharedStyles(theme);

  return {
    ...other,
    root: {
      ...root,
      color: theme.palette.common.white,
      background: theme.palette.secondary.light,
      [theme.media.md]: {
        textAlign: 'center',
        flexDirection: 'column-reverse',
        minHeight: 'auto',
      },
    },
    notInView: {
      ...notInView,
      marginLeft: 40,
    },
    inView: {
      ...inView,
      marginLeft: 0,
    },
    mobileLineBreak: {
      display: 'none',
      [theme.media.md]: {
        display: 'block',
      },
    },
    modalWrapper: {
      width: 'fit-content',
      [theme.media.md]: {
        margin: 'auto',
      },
    },
  };
};

export default injectSheet(styles)(OurGoal);
