import React, { useState } from 'react';
import injectSheet from 'react-jss';
import sharedStyles from '../sharedStyles';
import classNames from 'classnames';

// Components
import ReactInView from 'react-inview-monitor';
import EmojiButton from '../EmojiButton';
import useWindowSize from 'react-use/lib/useWindowSize';
import Confetti from 'react-confetti';

interface Props {
  classes: any;
}

function OurGoal({ classes }: Props) {
  const [showConfetti, setShowConfetti] = useState(null);
  const { width } = useWindowSize();

  function handleShowConfetti() {
    setShowConfetti(true);
    setTimeout(() => {
      setShowConfetti(false);
    }, 3000);
  }

  return (
    <section className={classes.root} id="first-section">
      <ReactInView
        classNameInView={classNames(classes.inView, classes.inViewContainer)}
        classNameNotInView={classNames(classes.notInView, classes.inViewContainer)}
      >
        <div className={classes.content}>
          <div className={classes.circle}>
            <div>
              <img
                src="/static/images/testnotify-icon.png"
                alt="testnotify icon"
                className={classes.image}
              />
            </div>
          </div>
        </div>
        <div className={classes.content}>
          <div className={classes.textBlock}>
            <h3 className={classes.title}>
              <div style={{ textAlign: 'center' }}>
                Our goal is to make
                <br />
                probation easier for you.
              </div>
            </h3>
            <p className={classes.subtitle}>
              We notify you the identifiers/colors from drug and alcohol testing centers. All
              requests from testing centers are saved and available to help prove you innocent in
              court.
              <EmojiButton
                emoji="🎉"
                label="Press for Confetti"
                onClick={handleShowConfetti}
                className={classes.button}
              />
            </p>
          </div>
        </div>
      </ReactInView>
      {showConfetti !== null && (
        // @ts-ignore
        <Confetti width={width - 20} height={600} recycle={!!showConfetti} numberOfPieces={1000} />
      )}
    </section>
  );
}

const styles = theme => {
  const { root, circle, subtitle, ...other } = sharedStyles(theme);
  return {
    ...other,
    root: {
      ...root,
      color: theme.palette.common.white,
      background: theme.palette.primary.main,
      position: 'relative',
    },
    inViewContainer: {
      [theme.media.md]: {
        textAlign: 'center',
        flexDirection: 'column',
      },
    },
    circle: {
      ...circle,
      [theme.media.md]: {
        height: 175,
        width: 175,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: theme.spacing.unit * 3,
      },
    },
    subtitle: {
      ...subtitle,
      textAlign: 'center',
    },
    image: {
      width: 170,
      height: 'auto',
      [theme.media.md]: {
        width: 100,
      },
    },
    button: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  };
};

export default injectSheet(styles)(OurGoal);
