export default (theme: any) => ({
  root: {
    minHeight: 600,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing.unit * 2,
  },
  notInView: {
    opacity: 0,
    transition: 'all 1s',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  inView: {
    opacity: 1,
    transition: 'all 1s',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    minWidth: 320,
  },
  circle: {
    height: 300,
    width: 300,
    borderRadius: '100%',
    background: theme.palette.common.white,
    margin: theme.spacing.unit * 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.media.md]: {
      height: 175,
      width: 175,
    },
  },
  textBlock: {
    maxWidth: theme.widths.section,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
  },
  title: {
    letterSpacing: '2px',
    fontFamily: "'TechnaSans', geneva",
    fontWeight: 400,
    marginTop: 0,
    fontSize: 40,
    marginBottom: theme.spacing.unit * 2,
    [theme.media.xs]: {
      fontSize: 26,
    },
  },
  subtitle: {
    // P Tag styles
    display: 'block',
    marginTop: 0,
    marginBottom: '1rem',
    marginBlockStart: '1em',
    marginBlockEnd: '1em',
    marginInlineStart: 0,
    marginInlineEnd: 0,

    fontWeight: 600,
    fontSize: 20,
    [theme.media.md]: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
    [theme.media.xs]: {
      fontSize: 14,
    },
  },
});
