import React from 'react';
import injectSheet from 'react-jss';
import sharedStyles from '../sharedStyles';

// Components
import ReactInView from 'react-inview-monitor';
import EmojiButton from '../EmojiButton';
import Link from 'next/link';

interface Props {
  classes: any;
}

function WeStore({ classes }: Props) {
  return (
    <section className={classes.root}>
      <div className={classes.content}>
        <ReactInView classNameInView={classes.inView} classNameNotInView={classes.notInView}>
          <div className={classes.circle} />
        </ReactInView>
      </div>
      <div className={classes.content}>
        <div className={classes.textBlock}>
          <h3 className={classes.title}>Every Test Center's requests are stored each day.</h3>
          <p className={classes.subtitle}>
            Need proof in court that you already tested twice that week? We store every test
            center's request for future reference.
            <Link href="/identifier-backlog">
              <EmojiButton emoji="📦" label="Identifier Backlog" />
            </Link>
          </p>
        </div>
      </div>
    </section>
  );
}

const styles = theme => {
  const { root, notInView, inView, title, ...other } = sharedStyles(theme);

  return {
    ...other,
    root: {
      ...root,
      color: theme.palette.common.white,
      background: theme.palette.primary.main,
      [theme.media.md]: {
        textAlign: 'center',
        flexDirection: 'column',
        minHeight: 'auto',
      },
    },
    notInView: {
      ...notInView,
      marginRight: 40,
    },
    inView: {
      ...inView,
      marginRight: 0,
    },
    title: {
      ...title,
      margin: 'auto',
      marginBottom: theme.spacing.unit * 3,
      textAlign: 'left',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      flexDirection: 'column',
      [theme.media.md]: {
        textAlign: 'center',
      },
    },
  };
};

export default injectSheet(styles)(WeStore);
