import React, { forwardRef } from 'react';
import injectSheet from 'react-jss';
import classNames from 'classnames';
import Button from '../Button';

interface Props {
  emoji: string;
  label: string;
  classes: any;
  className?: string;
  onClick?: (e: any) => void;
}

function EmojiButton({ className, onClick, classes, emoji, label }: Props, ref: any) {
  return (
    <Button
      color="white"
      className={classNames(classes.button, className)}
      onClick={onClick}
      ref={ref}
    >
      <span className={classes.buttonTitle}>{label}</span>
      <span className={classes.emoji}>{emoji}</span>
    </Button>
  );
}

const styles = theme => {
  return {
    button: {
      marginTop: theme.spacing.unit * 4,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    emoji: {},
    buttonTitle: {
      paddingRight: theme.spacing.unit / 1.5,
      paddingLeft: theme.spacing.unit / 1.5,
    },
  };
};

export default injectSheet(styles)(forwardRef(EmojiButton));
