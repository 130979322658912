import React from 'react';
import injectSheet from 'react-jss';
import sharedStyles from '../sharedStyles';

// components
import ReactInView from 'react-inview-monitor';

class WereMobile extends React.PureComponent {
  render() {
    const { classes } = this.props;

    return (
      <section className={classes.root}>
        <div className={classes.content}>
          <div className={classes.textBlock}>
            <h3 className={classes.title}>
              <strong>We are mobile.</strong>
            </h3>
            <p className={classes.subtitle}>
              <img
                className={classes.appleAppStore}
                src="https://imgur.com/x6d4lCL.png"
                alt="iOS download testnotify"
              />
              <img
                src="https://imgur.com/dRaeSWd.png"
                className={classes.googleBadge}
                alt="android download testnotify"
              />
            </p>
          </div>
        </div>
        <div className={classes.content}>
          <ReactInView classNameInView={classes.inView} classNameNotInView={classes.notInView}>
            <div className={classes.circle} />
          </ReactInView>
        </div>
      </section>
    );
  }
}

const styles = theme => {
  const { root, notInView, inView, circle, ...other } = sharedStyles(theme);

  return {
    ...other,
    root: {
      ...root,
      color: theme.palette.primary.contrastText,
      background: theme.palette.common.white,
      [theme.media.md]: {
        textAlign: 'center',
        flexDirection: 'column',
        minHeight: 'auto',
      },
    },
    notInView: {
      ...notInView,
      marginTop: 100,
    },
    inView: {
      ...inView,
      marginRight: 0,
    },
    circle: {
      ...circle,
      background: theme.palette.secondary.light,
    },
    appleAppStore: {
      marginLeft: theme.spacing.unit,
      marginBottom: theme.spacing.unit,
      width: 190,
    },
    googleBadge: {
      marginLeft: theme.spacing.unit,
      marginBottom: theme.spacing.unit,
      width: 188,
    },
  };
};

export default injectSheet(styles)(WereMobile);
