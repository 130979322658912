import React from 'react';
import PropTypes from 'prop-types';
import Fuse from 'fuse.js';
import _get from 'lodash/get';
import theme from '../../styles/theme';

// Components
import Autocomplete from 'react-autocomplete';
import SuggestLocation from '../modals/SuggestLocation';

// Style
import injectSheet from 'react-jss';
import classnames from 'classnames';
const styles = theme => {
  return {
    searchItem: {
      padding: theme.spacing.unit,
      borderBottom: [1, 'solid', theme.palette.common.divider],
      cursor: 'pointer',
      transition: 'background .3s',
    },
    suggestLocationItem: {
      background: theme.palette.common.gray,
    },
    title: {
      fontSize: 16,
      [theme.media.md]: {
        fontSize: 14,
      },
    },
    subheader: {
      fontSize: 14,
      [theme.media.md]: {
        fontSize: 13,
      },
    },
  };
};

const defaultMenuStyle = {
  borderRadius: 3,
  background: 'rgba(255, 255, 255, 0.9)',
  paddingTop: 2,
  fontSize: '90%',
  maxHeight: 400,
  overflowY: 'auto',
  zIndex: 1,
  marginTop: -2,
  border: `1px solid ${theme.palette.common.divider}`,
};

const SUGGEST_LOCATION = 'Suggest Testing Center';

class LocationAC extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      input: _get(props, 'defaultValue.name') ? props.defaultValue.name : '',
      items: [],
      selectedValue: null,
      modalOpen: false,
    };

    this.input = React.createRef();
  }

  componentDidMount() {
    if (this.props.focus) {
      this.input.current.focus();
    }
  }

  componentDidUpdate(prevProps) {
    const { cancelId, defaultValue } = this.props;
    if (prevProps.cancelId !== cancelId) {
      this.setState(() => ({
        input: _get(defaultValue, 'name') ? defaultValue.name : '',
        items: [],
        selectedValue: null,
      }));
    }
  }

  handleChange = e => {
    const searchTerm = e.target.value;
    const { suggestLocation, onChange, testLocations = [] } = this.props;
    const options = {
      shouldSort: true,
      keys: ['name', 'address'],
    };

    const fuse = new Fuse(testLocations, options);
    const items = fuse.search(searchTerm);
    items.splice(5, items.length);

    if (suggestLocation) {
      items.push({ name: SUGGEST_LOCATION });
    }

    if (onChange) {
      onChange(e.target.value);
    }

    this.setState({
      input: e.target.value,
      items: e.target.value.length ? items : [],
    });
  };

  handleSelect = (value, testLocation) => {
    const { suggestLocation, onSelect, closeOnSelect } = this.props;

    if (value === SUGGEST_LOCATION && suggestLocation) {
      return this.toggleModal();
    }

    this.setState(prevState => ({
      selectedValue: testLocation,
      input: value,
      items: closeOnSelect ? [] : prevState.items,
      prevItems: prevState.items,
    }));

    onSelect(testLocation);
  };

  handleFocus = () => {
    this.setState(prevState => ({
      items: prevState.prevItems ? prevState.prevItems : [],
      prevItems: [],
    }));
  };

  handleBlur = () => {
    const { closeOnSelect } = this.props;

    this.setState(prevState => ({
      items: closeOnSelect ? [] : prevState.items,
      prevItems: prevState.items,
      input: prevState.selectedValue ? prevState.selectedValue.name : prevState.input,
    }));
  };

  handleKeyDown = e => {
    const { onChange } = this.props;
    if (e.keyCode === 27) {
      if (onChange) {
        onChange('');
      }

      this.setState(prevState => ({
        input: '',
        items: [],
        prevItems: prevState.items,
      }));
    }
  };

  toggleModal = () => {
    this.input.current.blur();
    this.setState(prevState => ({
      modalOpen: !prevState.modalOpen,
    }));
  };

  render() {
    const { classes, className, placeholder, suggestLocation, inputProps } = this.props;

    return (
      <>
        <Autocomplete
          menuStyle={
            this.state.items.length === 0
              ? {
                  display: 'none',
                }
              : defaultMenuStyle
          }
          getItemValue={item => item.name}
          items={this.state.items}
          renderItem={(item, isHighlighted) => {
            const isSuggestLocationItem = item.name === SUGGEST_LOCATION;

            return (
              <div
                className={classnames(classes.searchItem, {
                  [classes.suggestLocationItem]: isSuggestLocationItem,
                })}
                style={{ background: isHighlighted && theme.palette.common.gray }}
                key={item.name}
              >
                <div className={classes.title}>{item.name}</div>
                <div className={classes.subheader}>{item.address}</div>
              </div>
            );
          }}
          open={!!this.state.input.length}
          value={this.state.input}
          onChange={this.handleChange}
          onSelect={this.handleSelect}
          inputProps={{
            ...inputProps,
            placeholder: placeholder !== undefined ? placeholder : 'Search Test Center...',
            style: {
              border:
                this.state.items.length !== 0
                  ? `1px solid ${theme.palette.common.divider}`
                  : undefined,
            },
            className: className + ' .onloadInput',
            onBlur: this.handleBlur,
            onFocus: this.handleFocus,
            onKeyDown: this.handleKeyDown,
            'aria-label': placeholder !== undefined ? placeholder : 'Search Test Center...',
          }}
          ref={this.input}
        />
        {suggestLocation && (
          <SuggestLocation open={this.state.modalOpen} toggleModal={this.toggleModal} />
        )}
      </>
    );
  }
}

LocationAC.propTypes = {
  testLocations: PropTypes.array,
  suggestLocation: PropTypes.bool,
  focus: PropTypes.bool,
  defaultValue: PropTypes.object,
  placeholder: PropTypes.string,
  inputProps: PropTypes.any,

  onSelect: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  closeOnSelect: PropTypes.bool,
  cancelId: PropTypes.number,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default injectSheet(styles)(LocationAC);
