import React from 'react';
import fetchLocationProfileList from '@testnotify/core/api/fetch-location-profile-list';

// Components
import Page from '../components/Page';
import LandingSection from '../components/homePageSections/LandingSection';
import OurGoal from '../components/homePageSections/OurGoal';
import WeNotify from '../components/homePageSections/WeNotify';
import WeStore from '../components/homePageSections/WeStore';
import WereNational from '../components/homePageSections/WereNational';
import WereMobile from '../components/homePageSections/WereMobile';
import Head from 'next/head';
import injectSheet from 'react-jss';

const styles = theme => {
  return {
    root: {
      maxWidth: 'initial',
      display: 'block',
      padding: 0,
      marginTop: 0,
    },
    noSpace: {
      padding: 0,
    },
  };
};

class HomePage extends React.PureComponent {
  static async getInitialProps(ctx) {
    return fetchLocationProfileList()
      .then(({ locationProfiles }) => {
        return {
          testLocations: locationProfiles,
        };
      })
      .catch(error => {
        return {
          testLocations: undefined,
        };
      });
  }

  render() {
    // @ts-ignore
    const { classes, testLocations } = this.props;

    return (
      <>
        <Head>
          <title>Search Testing Centers - TestNotify</title>
          <meta
            name="description"
            content="TestNotify makes probation easier by sending you notifications when you need to test for drugs and alcohol."
          />
        </Head>
        <Page classes={{ children: classes.root, footerSpace: classes.noSpace }}>
          <LandingSection testLocations={testLocations} />
          <OurGoal />
          <WeNotify />
          <WeStore />
          <WereNational />
          <WereMobile />
        </Page>
      </>
    );
  }
}

// @ts-ignore
export default injectSheet(styles)(HomePage);
