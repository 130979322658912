import React from 'react';
import PropTypes from 'prop-types';
import Router from 'next/router';
import root from 'window-or-global';

// Components
import LocationAC from '../../LocationAC';
import Icon from '../../Icon';
import ModalWrapper from '../../ModalWrapper';
import LogoImage from '../../LogoImage';

// Style
import injectSheet from 'react-jss';
import classnames from 'classnames';

const listRowHeight = 62.5;

class LandingSection extends React.PureComponent {
  state = {
    search: '', // only used to display or hide recent searches
    list: [],
  };

  componentDidMount() {
    if (root.localStorage) {
      const recentSearchData = root.localStorage.getItem('recentSearches');

      this.setState({
        list: JSON.parse(recentSearchData) || [],
      });

      setTimeout(() => {
        this.setState({
          appLoaded: true,
        });
      }, 100);
    }
  }

  handleChange = value => {
    this.setState({
      search: value,
    });
  };

  handleSelect = value => {
    const { list } = this.state;

    if (root.localStorage) {
      const data = JSON.parse(root.localStorage.getItem('recentSearches')) || list;
      const dataCloned = data.slice();
      const valueIsInList = dataCloned.filter(item => {
        return value.id === item.id;
      });

      const addToList = !valueIsInList.length;
      if (addToList) {
        if (dataCloned.length >= 3) {
          dataCloned.pop();
        }
        dataCloned.unshift(value);
      }

      root.localStorage.setItem('recentSearches', JSON.stringify(dataCloned));
    }

    Router.push(`/location/${value.slug}`);
  };

  handleTestCenterSlug = (slug, type) => {
    return e => {
      if (type === 'click' || e.keyCode === 13) {
        Router.push(`/location/${slug}`);
      }
    };
  };

  handleDelete = (index, type) => {
    const { list } = this.state;
    return e => {
      if (type === 'click' || e.keyCode === 13) {
        let dataCloned = list.slice();
        dataCloned.splice(index, 1);
        if (root.localStorage) {
          root.localStorage.setItem('recentSearches', JSON.stringify(dataCloned));
        }

        this.setState({
          list: dataCloned,
        });
      }
    };
  };

  render() {
    const { classes, testLocations } = this.props;
    const { list, search, appLoaded } = this.state;
    const onLoadClass = appLoaded ? classes.onLoad : null;
    const listHeightStyle = appLoaded && list.length ? list.length * listRowHeight + 56 : 0;

    return (
      <section className={classes.root}>
        <div className={classes.content}>
          <h1 className={classes.logo}>
            <LogoImage />
            <span className={classes.logoText + ' onLoadFont'}>Test</span>
            <span className={classes.logoText + ' onLoadFont'}>Notify</span>
          </h1>
          <div className={classes.subheader}>Drug/Alcohol Test Center Search</div>
          <LocationAC
            onSelect={this.handleSelect}
            onChange={this.handleChange}
            focus
            suggestLocation
            className={classes.searchbar}
            testLocations={testLocations}
          />

          {!search.length && (
            <div
              className={classnames(onLoadClass, classes.list)}
              style={{ minHeight: listHeightStyle }}
            >
              {!!list.length && (
                <>
                  <div className={classes.listItemTitle}>Recent Searches</div>
                  <div className={classes.listItemContainer}>
                    {list.map((item, index) => {
                      return (
                        item && (
                          <div className={classes.listItemRow} key={index}>
                            <div
                              className={classes.listItemContent}
                              onClick={this.handleTestCenterSlug(item.slug, 'click')}
                              onKeyDown={this.handleTestCenterSlug(item.slug)}
                              tabIndex="0"
                            >
                              <div className={classes.listItemLabel}>{item.name}</div>
                              {item.address && (
                                <div className={classes.listItemSubheader}>{item.address}</div>
                              )}
                            </div>
                            <div
                              className={classes.listItemIcon}
                              onClick={this.handleDelete(index, 'click')}
                              onKeyDown={this.handleDelete(index)}
                              tabIndex="0"
                            >
                              <Icon>close</Icon>
                            </div>
                          </div>
                        )
                      );
                    })}
                  </div>
                </>
              )}
            </div>
          )}
          {!search.length && (
            <div className={classes.suggestLocationContainer}>
              <div>Can't find a testing center?</div>
              <ModalWrapper className={classes.aTagStyles} type="SuggestLocation">
                Suggest a testing center
              </ModalWrapper>
            </div>
          )}
        </div>
        <div className={classes.bottom}>
          <div className={classes.bottomButton}>
            <Icon>arrow_downward</Icon>
          </div>
        </div>
      </section>
    );
  }
}

const styles = theme => {
  const footerHeight = 80;
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      marginTop: theme.spacing.unit * 8,
      [theme.media.sm]: {
        marginTop: theme.spacing.unit,
      },
    },
    content: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexDirection: 'column',
      flex: '1 1 auto',
      padding: [0, theme.spacing.unit * 2, theme.spacing.unit * 2, theme.spacing.unit * 2],
      height: `calc(100vh - ${theme.heights.navbar + footerHeight}px)`,
      minHeight: 675,
      [theme.media.md]: {
        height: `calc(100vh - ${theme.heights.navbar + footerHeight}px)`,
        padding: theme.spacing.unit / 2,
        '& h2, & input': {
          fontSize: 17,
        },
      },
      [theme.media.sm]: {
        paddingTop: theme.spacing.unit * 4,
      },
    },
    bottom: {
      marginTop: -theme.spacing.unit * 12,
      height: footerHeight,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
    },
    bottomButton: {
      width: 40,
      height: 40,
      top: 0,
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50%',
      background: theme.palette.common.gray,
      color: theme.palette.primary.contrastText,
      animation: 'hover 1.25s infinite',
      cursor: 'pointer',
      zIndex: -1,
    },
    logo: {
      display: 'flex',
      '& img': {
        width: 95,
        marginRight: 2,
        marginTop: -3,
      },
      [theme.media.md]: {
        '& img': {
          width: 75,
        },
      },
      [theme.media.xs]: {
        '& img': {
          width: 62,
        },
      },
    },
    logoText: {
      fontSize: 70,
      color: theme.palette.primary.main,
      fontWeight: 700,

      [theme.media.md]: {
        fontSize: 55,
      },
      [theme.media.xs]: {
        fontSize: 45,
      },
    },
    subheader: {
      fontSize: 18,
      marginBottom: theme.spacing.unit,
      textAlign: 'center',
      padding: [0, theme.spacing.unit],
    },
    searchbar: {
      '-webkit-appearance': 'none',
      borderRadius: 0,
      fontSize: 20,
      padding: theme.spacing.unit * 1.5,
      width: '100%',
      maxWidth: 550,
      minWidth: 550,
      background: theme.palette.common.gray,
      marginTop: theme.spacing.unit * 3,
      border: [1, 'solid', theme.palette.common.divider],
      [theme.media.md]: {
        maxWidth: 450,
        minWidth: 450,
        marginTop: 0,
      },
      [theme.media.xs]: {
        width: '100%',
        maxWidth: 'inherit',
        minWidth: 300,
      },
    },
    list: {
      opacity: 0,
      height: 0,
      transition: 'all .4s',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    onLoad: {
      opacity: 1,
    },
    listItemTitle: {
      fontSize: 16,
      marginTop: theme.spacing.unit * 3.5,
      marginBottom: theme.spacing.unit,
    },
    listItemContainer: {
      borderTop: [1, 'solid', theme.palette.common.divider],
      width: 450,
      [theme.media.xs]: {
        width: '100%',
        minWidth: 300,
      },
    },
    listItemRow: {
      display: 'flex',
      borderBottom: [1, 'solid', theme.palette.common.divider],
      borderLeft: [1, 'solid', theme.palette.common.divider],
      borderRight: [1, 'solid', theme.palette.common.divider],
    },
    listItemContent: {
      flex: 1,
      cursor: 'pointer',
      transition: 'background .3s',
      padding: theme.spacing.unit,
      width: 'calc(492px - 100px)',
      '&:hover': {
        background: theme.palette.common.gray,
      },
      [theme.media.xs]: {
        width: 'calc(100vw - 180px)',
      },
    },
    listItemIcon: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minWidth: 50,
      cursor: 'pointer',
      transition: 'background .3s',
      marginTop: -1,
      borderLeft: [1, 'solid', theme.palette.common.divider],
      borderTop: [1, 'solid', theme.palette.common.divider],
      '&:hover': {
        background: theme.palette.error.light,
      },
    },
    listItemLabel: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      width: '100%',
      textOverflow: 'ellipsis',
      marginBottom: theme.spacing.unit / 2,
    },
    listItemSubheader: {
      fontSize: 14,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      width: '100%',
      textOverflow: 'ellipsis',
    },
    aTagStyles: {
      color: theme.palette.primary.main,
      fontWeight: 600,
      fontSize: 14,
      cursor: 'pointer',
      textDecoration: 'underline',
    },
    suggestLocationContainer: {
      marginTop: theme.spacing.unit * 1.5,
      textAlign: 'center',
    },
  };
};

LandingSection.propTypes = {
  classes: PropTypes.object.isRequired,
  testLocations: PropTypes.array.isRequired,
};

export default injectSheet(styles)(LandingSection);
