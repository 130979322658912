import React from 'react';
import injectSheet from 'react-jss';
import sharedStyles from '../sharedStyles';

// Components
import ReactInView from 'react-inview-monitor';
import EmojiButton from '../EmojiButton';
import Icon from '../../Icon';
import Link from 'next/link';
import Button from '../../Button';

interface Props {
  classes: any;
  hideFAQ?: boolean;
}

function WeNotify({ classes, hideFAQ }: Props) {
  return (
    <section className={classes.root}>
      <div className={classes.content}>
        <div className={classes.textBlock}>
          <h3 className={classes.title}>Wake up everyday to a notification.</h3>
          <div className={classes.subtitle}>
            End the worry of missing a test. We notify you everyday at any specific time if you are
            called to test or not.
            <ul className={classes.notificationList}>
              <li className={classes.copyPop}>
                <Icon>phone</Icon> Phone Call
              </li>
              <li className={classes.copyPop}>
                <Icon>chat</Icon> Text Message
              </li>
              <li className={classes.copyPop}>
                <Icon>email</Icon> Email
              </li>
              <li className={classes.copyPop}>
                <Icon>notifications_active</Icon> Push Notification
              </li>
            </ul>
            <Link href="/setup-notifications">
              <EmojiButton emoji="✅" label="Setup Notifications" />
            </Link>
            {!hideFAQ && (
              <>
                <div className={classes.spacer} />
                <Link href={`/faq/notifications`}>
                  <Button color="hyperlinkWhite">Learn More About Notifications</Button>
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
      <div className={classes.content}>
        <ReactInView classNameInView={classes.inView} classNameNotInView={classes.notInView}>
          <div className={classes.circle} />
        </ReactInView>
      </div>
    </section>
  );
}

const styles = theme => {
  const { root, notInView, inView, title, textBlock, subtitle, ...other } = sharedStyles(theme);

  return {
    ...other,
    root: {
      ...root,
      color: theme.palette.common.white,
      background: theme.palette.secondary.light,
      [theme.media.md]: {
        textAlign: 'center',
        flexDirection: 'column-reverse',
        minHeight: 'auto',
      },
    },
    notInView: {
      ...notInView,
      marginLeft: 40,
    },
    inView: {
      ...inView,
      marginLeft: 0,
    },
    textBlock: {
      ...textBlock,
    },
    title: {
      ...title,
      [theme.media.md]: {
        textAlign: 'center',
      },
    },
    subtitle: { ...subtitle },
    copyPop: {
      display: 'flex',
      alignItems: 'center',
      '& span': {
        marginRight: theme.spacing.unit,
      },
    },
    notificationList: {
      marginTop: theme.spacing.unit * 2,
      [theme.media.md]: {
        paddingLeft: 0,
      },
    },
    learnMore: {
      fontSize: 16,
      marginTop: theme.spacing.unit * 2,
      color: theme.palette.common.white,

      '&:hover': {
        color: theme.palette.common.white + ' !important',
        textDecoration: 'underline',
      },
    },
    spacer: {
      marginBottom: theme.spacing.unit,
    },
  };
};

export default injectSheet(styles)(WeNotify);
